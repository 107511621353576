// We have user errors, which are not graphql errors. We need to handle them separately
import { useNotification } from '@base/composables/useNotifications'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import type { NotificationType } from '@ui/composables/useNotificationsUi'

export function useEcomGqlUserErrors() {
  const addToCartNotificationType = 'toast' as NotificationComponentType
  const { showNotification } = useNotification(addToCartNotificationType)

  type ErrorData = Record<string, unknown> | Record<string, unknown>[]

  const showToast = ({ message, type }: { message: string, type: NotificationType }) => {
    showNotification({
      id: crypto.randomUUID(),
      type,
      message,
      closable: true,
      timeout: 5000,
    })
  }

  const handleUserError = <T>(
    errorData: T,
    showError = true,
  ): string | ErrorData | null | unknown => {
    if (!errorData || !Array.isArray(errorData)) {
      return null
    }

    const message = errorData[0]?.message as string

    if (message && showError) {
      showToast({
        message,
        type: 'error',
      })
      return message
    }

    return errorData as unknown
  }

  const handleCartItemsError = <T extends Array<{
    product: { sku?: string | null | undefined } | null | undefined
    errors?: Array<{ message: string, code: string } | null> | null | undefined
  } | null | undefined> | null | undefined>(
    cartItems: T,
    showError = true,
  ): string | ErrorData | null | unknown => {
    if (!cartItems) {
      return null
    }

    const errorMessages: string[] = []

    cartItems.forEach((item) => {
      if (item?.errors && item.errors.length > 0) {
        const errorMessage = item.errors
          .map(error => `${item?.product?.sku ?? ''} - ${error?.message}`)
          .join('\n')

        errorMessages.push(errorMessage)
      }
    })

    const message: string = errorMessages.join(', ')

    if (!message) {
      return null
    }

    if (showError) {
      showToast({
        message,
        type: 'warning',
      })
      return message
    }

    return errorMessages
  }
  return {
    handleUserError,
    handleCartItemsError,
  }
}
